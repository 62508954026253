<template>
	<div>
		<h2>{{ $t("horse.secondary") }}</h2>
		<div class="box">
            <CustomTable
                id_table="horse_tiers_secondaires"
                :items="filtredTiersSecondaires"
                :busy.sync="table_busy"
				:rawColumns="rawColumnNames"
                primaryKey="tiershorse_id"
				:hrefsRoutes="config_table_hrefs"
            />
		</div>
	</div>
</template>

<script type="text/javascript">
	import Common from '@/assets/js/common'
	import ContactMixin from '@/mixins/Contact.js'
	import FonctionMixin from '@/mixins/Fonction.js'
	import Navigation from '@/mixins/Navigation.js'
	import HorseMixin from '@/mixins/Horse.js'
	import TiersMixin from '@/mixins/Tiers.js'

	export default {
		name: "TiersSecondaires",
		mixins: [ContactMixin, FonctionMixin, Navigation, HorseMixin, TiersMixin],
		props: ['tiers_id'],
		data() {
			return {
				tiers_secondaires: null,
				table_busy: true,
				tiers_horse_part: null,
				config_table_hrefs: {
					'horse.horse_nom': {
						routeUniqueName: 'HorseFicheInfo',
						routeName: 'HorseFicheInfo',
						params: {
							horse_id: 'horse.horse_id'
						}
					}
				},
				rawColumnNames: ['contact_phones_number', 'tiers_fonction'],
			}
		},
		created() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.tiers_secondaires = await this.loadHorseTiersSecondaires(this.tiers_id)
				this.relatedTiers = await this.loadRelatedTiers(this.tiers_id)
				await Common.asyncForEach(
					this.relatedTiers, 
					async(tiers_relations) => {
						let horse_current_part = null
						
						horse_current_part = await this.loadHorseCurrentPart(tiers_relations.tiersrelations_from.tiers_id)
						horse_current_part.tiers_horse.forEach(tiers_horse => {
							let temp = {}
							
							temp.tiershorse_id = tiers_horse.tiershorse_id
							temp.horse = tiers_horse.horse
							temp.tiers = horse_current_part
							temp.fonction = tiers_relations.fonction
							temp.tierscontact = tiers_relations.contact

							this.tiers_secondaires.push(temp)
						});
					}
				);
				this.table_busy = false
			},
		},
		computed: {
			filtredTiersSecondaires: function() {
				let tiersSecondairesFormated = []
				if(!this.tiers_secondaires) {
					return []
				}

				const copy_temp = this.deppCloneObj(this.tiers_secondaires)
				for(let i = 0; i < copy_temp.length; i++) {
					let temp = {}
					if(this.tiers_secondaires[i].tierscontact) {
						temp = {...copy_temp[i], ...copy_temp[i].tierscontact.contact}
						temp.contact_phones_number = this.getArrayObjProperty(temp.phones, "phone_combine").join("<br>")
					}
					else {
						temp = {...copy_temp[i]}
						temp.contact_phones_number = null
					}
					temp.tiers_fonction = this.getTrad(temp.fonction.fonction_label)
					tiersSecondairesFormated.push(temp)
				}

				return tiersSecondairesFormated
			}
		},
        components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			ModalTiersSecondaire: () => import('@/components/Horse/ModalTiersSecondaire')
        }
	}
</script>